import React from "react"
import SinglePageHeader from "../../components/SinglePageLayout"
import { useTranslation } from "gatsby-plugin-react-i18next"

export default function Csr() {
  const { t } = useTranslation()

  return (
    <SinglePageHeader title="ICH Bursary" bg="/assets/img/csr-banner.jpg">
      <article className="ich-container ich-post">
        <h3 className="post-title">ICH {t("open-bursary")}</h3>
        <p>{t("all-levels")}</p>
        <hr />
        <p>{t("bursary-all-levels-p1")}</p>
        <p>{t("bursary-all-levels-p2")}</p>
        <p>
          {t("bursary-all-levels-p3-1")}{" "}
          <a href="mailto:contact@ich-group.com" className="ich-pink-link">
            contact@ich-group.com
          </a>
          . {t("bursary-all-levels-p3-2")}
        </p>
        <p>{t("bursary-all-levels-p4")}</p>
        <p>{t("bursary-all-levels-p5")}</p>
      </article>
      {/* <a
        href="/assets/files/application-form.pdf"
        className="pink-button"
        download
        style={{ marginBottom: 20, width: 300, textAlign: "center" }}
      >
        {t("download-application-form")}
      </a> */}
    </SinglePageHeader>
  )
}
